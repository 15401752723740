<template>
  <div class="event-display-title">
    <div
      class="event-display-title__row-1"
      :class="{
        'event-display-title__row-1--detection': is_detection,
        'event-display-title__row-1--initialisation': is_initialisation,
        'event-display-title__row-1--activation': is_activation,
        'event-display-title__row-1--deactivation': is_deactivation,
        'event-display-title__row-1--crash': is_crash,
      }"
    >
      <EventDisplayTitleIcon
        class="event-display-title__row-1__element"
        :event_type="event_type"
      />
      <h1 class="event-display-title__row-1__element">{{ event_type }}</h1>
      <h4
        class="
          event-display-title__row-1__element
          event-display-title__row-1__crucial-widget
        "
      >
        {{ readable_time }}
      </h4>
      <p
        class="
          event-display-title__row-1__element
          event-display-title__row-1__info-widget
        "
        v-if="camera_is_not_na"
      >
        {{ camera }}
      </p>
      <p
        class="
          event-display-title__row-1__element
          event-display-title__row-1__info-widget
        "
        v-if="num_entities_is_not_na"
      >
        {{ num_tracked_entities }} entities tracked
      </p>
    </div>

    <div
      class="event-display-title__row-2"
      :class="{
        'event-display-title__row-2--detection': is_detection,
        'event-display-title__row-2--initialisation': is_initialisation,
        'event-display-title__row-2--activation': is_activation,
        'event-display-title__row-2--deactivation': is_deactivation,
        'event-display-title__row-2--crash': is_crash,
      }"
    >
      <p>{{ invoking_rule }}</p>
    </div>
  </div>
</template>

<script>
import EventDisplayTitleIcon from "@/components/EventDisplayTitleIcon.vue";

import {
  get_readable_date_from_timestamp,
  get_readable_time_from_timestamp,
} from "@/utils";

import {
  SYSTEM_MESSAGE_TYPES,
  NOT_APPLICABLE_STRING,
} from "@/utils/cortex_constants";

export default {
  name: "EventDisplayTitle",
  components: {
    EventDisplayTitleIcon,
  },
  props: {
    timestamp: {
      type: String,
      required: true,
    },
    event_type: {
      type: String,
      required: true,
    },
    invoking_rule: {
      type: String,
      required: true,
    },
    camera: {
      type: String,
      required: true,
    },
    num_tracked_entities: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    readable_date: function () {
      return get_readable_date_from_timestamp(this.timestamp);
    },
    readable_time: function () {
      return get_readable_time_from_timestamp(this.timestamp);
    },
    camera_is_not_na: function () {
      return this.camera !== NOT_APPLICABLE_STRING;
    },
    num_entities_is_not_na: function () {
      return this.num_tracked_entities !== NOT_APPLICABLE_STRING;
    },
    is_detection: function () {
      return this.event_type == SYSTEM_MESSAGE_TYPES.MONITORING_EVENT;
    },
    is_initialisation: function () {
      return this.event_type == SYSTEM_MESSAGE_TYPES.INITIALISATION_EVENT;
    },
    is_activation: function () {
      return this.event_type == SYSTEM_MESSAGE_TYPES.ACTIVATION_EVENT;
    },
    is_deactivation: function () {
      return this.event_type == SYSTEM_MESSAGE_TYPES.DEACTIVATION_EVENT;
    },
    is_crash: function () {
      return this.event_type == SYSTEM_MESSAGE_TYPES.CRASH_EVENT;
    },
  },
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.event-display-title {
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.event-display-title__row-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 10px 10px 0px 0px;
  width: 95%;

  border: 2px solid mediumspringgreen;
  background-color: mediumspringgreen;
  color: black;
}

.event-display-title__row-1--detection,
.event-display-title__row-2--detection {
  border: 2px solid mediumspringgreen;
  background-color: mediumspringgreen;
  color: black;
}

.event-display-title__row-2--detection {
  color: mediumspringgreen;
}

.event-display-title__row-1--initialisation,
.event-display-title__row-2--initialisation {
  border: 2px solid rgb(87, 87, 87);
  background-color: rgb(87, 87, 87);
  color: rgb(145, 145, 145);
}

.event-display-title__row-1--activation,
.event-display-title__row-2--activation {
  border: 2px solid rgb(84, 114, 84);
  background-color: rgb(84, 114, 84);
  color: rgb(145, 145, 145);
}

.event-display-title__row-1--deactivation,
.event-display-title__row-2--deactivation {
  border: 2px solid rgb(114, 84, 84);
  background-color: rgb(114, 84, 84);
  color: rgb(145, 145, 145);
}

.event-display-title__row-1--crash,
.event-display-title__row-2--crash {
  border: 2px solid rgb(252, 68, 68);
  background-color: rgb(252, 68, 68);
  color: white;
}

.event-display-title__row-1__element {
  margin: 5px;
}

.event-display-title__row-1__crucial-widget {
  background-color: royalblue;
  padding: 3px 10px 3px 10px;
  border-radius: 10px;
  color: white;
  font-weight: bold;
}

.event-display-title__row-1__info-widget {
  background-color: rgb(245, 185, 35);
  padding: 3px 10px 3px 10px;
  border-radius: 10px;
  color: white;
  font-weight: bold;
}

.event-display-title__row-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 0px 0px 10px 10px;
  text-align: center;
  padding: 5px 0px 5px 0px;
  width: 95%;

  background-color: #3a3a3a;
  border-bottom: 2px solid mediumspringgreen;
  border-left: 2px solid mediumspringgreen;
  border-right: 2px solid mediumspringgreen;
}

.event-display-title__row-2 p {
  width: 90%;
}
</style>
