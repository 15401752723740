import { createStore } from "vuex";
import { actions } from "@/store/actions.js";
import { mutations } from "@/store/mutations.js";
import { getters } from "@/store/getters.js";
import { get_todays_date } from "@/utils";

export const get_default_state = function () {
  return {
    user: null,
    location_id: "stormy-nation-tale",
    location_name: "brickfield-farm",
    display_date: get_todays_date(),
    available_dates: [],
    screen_width: 1000,
    days_events: [],
    latest_health_check: {},
    supports_notifications: false,
    last_notification_time: -1,
    last_notification_event_types: [],
    system_mode_user_set: "",
    set_system_mode_from_firestore: "",
    running_system_mode: "",
    used_hard_drive_percentage: -1,
    entities: {},
  };
};

export default createStore({
  state: get_default_state(),
  mutations: mutations,
  getters: getters,
  actions: actions,
  modules: {},
});
