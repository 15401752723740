<template>
  <div
    class="the-message-bar the-message-bar--dark-mode"
    :class="{
      'the-message-bar--wide-layout': !has_narrow_screen,
      'the-message-bar--mobile-layout': has_narrow_screen,
    }"
  >
    <TheDateTimeDisplay />

    <div
      v-if="has_narrow_screen"
      class="the-message-bar__expander"
      :class="{
        'the-message-bar__expander--expanded-layout': expand_menu,
        'the-message-bar__expander--closed-layout': !expand_menu,
      }"
      @click="expand_clicked()"
    >
      <div
        class="collapser-bar"
        :class="{
          'collapser-bar--horizontal': !expand_menu,
          'collapser-bar--vertical': expand_menu,
        }"
      ></div>
      <div
        class="collapser-bar"
        :class="{
          'collapser-bar--horizontal': !expand_menu,
          'collapser-bar--vertical': expand_menu,
        }"
      ></div>
      <div
        class="collapser-bar"
        :class="{
          'collapser-bar--horizontal': !expand_menu,
          'collapser-bar--vertical': expand_menu,
        }"
      ></div>
    </div>

    <div
      v-if="!has_narrow_screen || expand_menu"
      class="the-message-bar__status-container"
      :class="{
        'the-message-bar__status-container--wide-layout': !has_narrow_screen,
        'the-message-bar__status-container--mobile-layout': has_narrow_screen,
      }"
    >
      <template v-if="have_health_data">
        <SystemStatusDisplay
          v-for="(item, idx) in node_health"
          :key="`${item.node_name}-health-display-${idx}`"
          :name="item.node_name"
          :status="item.recent_ping ? 'OK' : 'ERROR'"
        />
      </template>
    </div>
  </div>
</template>

<script>
import TheDateTimeDisplay from "@/components/TheDateTimeDisplay.vue";
import SystemStatusDisplay from "@/components/SystemStatusDisplay.vue";
import { NO_HEALTH_DATA } from "@/store/getters";

import { mapState, mapGetters } from "vuex";

export default {
  name: "TheMessageBar",
  components: {
    TheDateTimeDisplay,
    SystemStatusDisplay,
  },
  props: {},
  computed: {
    ...mapState(["screen_width"]),
    ...mapGetters(["has_narrow_screen", "node_health"]),
    have_health_data: function () {
      return this.node_health !== NO_HEALTH_DATA;
    },
  },
  data() {
    return {
      expand_menu: false,
    };
  },
  mounted() {},
  methods: {
    expand_clicked: function () {
      this.expand_menu = !this.expand_menu;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.the-message-bar {
  width: 100%;
  margin-bottom: 30px;
}

.the-message-bar--dark-mode {
  /* border-bottom: 1px solid mediumspringgreen; */
  color: mediumspringgreen;
  /* background-color: #9b9b9b; */
  box-shadow: 0px 3px 10px mediumspringgreen;
}

.the-message-bar--wide-layout {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.the-message-bar--mobile-layout {
  display: grid;
  grid-template:
    [row1-start] "dt-display expander" [row1-end]
    [row2-start] "status status" [row2-end]
    / 9fr 1fr;
}

.the-message-bar__expander {
  grid-area: expander;
  margin: 0px 10px 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.the-message-bar__expander--closed-layout {
  flex-direction: column;
}

.the-message-bar__expander--expanded-layout {
  flex-direction: row;
}

.collapser-bar--horizontal {
  width: 30px;
  height: 2px;
  margin-bottom: 5px;
  background-color: mediumspringgreen;
}

.collapser-bar--vertical {
  width: 2px;
  height: 20px;
  margin-right: 5px;
  background-color: mediumspringgreen;
}

.the-message-bar__status-container--wide-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.the-message-bar__status-container--mobile-layout {
  grid-area: status;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow: auto;
  /* flex-direction: column; */
}
</style>
