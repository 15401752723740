<template>
  <div class="event-display-image">
    <div class="event-display-image__downward-arrows" v-if="have_an_img_src">
      &#x25BC; &#x25BC; &#x25BC;
    </div>
    <img
      class="event-display-image__image"
      :src="img_src"
      alt="Event Image"
      v-if="have_an_img_src"
    />
    <div class="event-display-image__message" v-else>
      <h1>{{ message }}</h1>
    </div>
  </div>
</template>

<script>
import {
  ERROR_INDICATOR,
  LOADING_INDICATOR,
  NO_IMAGE_INDICATOR,
} from "@/utils/cortex_constants";

export default {
  name: "EventDisplayImage",
  components: {},
  props: {
    img_src: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  computed: {
    have_an_img_src: function () {
      return this.img_src.trim() !== "";
    },
    image_is_loading: function () {
      return this.message.trim().includes(LOADING_INDICATOR);
    },
    error_getting_image: function () {
      return this.message.trim().includes(ERROR_INDICATOR);
    },
    no_image: function () {
      return this.message.trim().includes(NO_IMAGE_INDICATOR);
    },
  },
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.event-display-image__image {
  /* width: 200px; */
  max-width: 90%;
}

.event-display-image__downward-arrows {
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
