<template>
  <div class="node-title">
    <h2>I am a node title</h2>
  </div>
</template>

<script>
export default {
  name: "NodeTitle",
  props: {},
  computed: {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.node-title {
  background-color: mediumseagreen;
}
</style>
