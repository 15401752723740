<template>
  <div class="the-actuators-panel">
    <h1>Actuators Panel</h1>
    This is a future feature, for now the display is set to None.
  </div>
</template>

<script>
export default {
  name: "TheActuatorsPanel",
  components: {},
  props: {},
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.the-actuators-panel {
  display: none;
}
</style>
