<template>
  <TheNavBar v-if="user" />
  <NodeStatus
    v-for="(item, key, index) in latest_health_check"
    :key="`node-status-${index}`"
    :name="key"
    :latest_data="item"
    :latest_event_type="get_latest_event_type"
    :narrow="has_narrow_screen"
    @click="node_status_clicked(key)"
  />
  <router-view />
</template>

<script>
import TheNavBar from "@/components/TheNavBar.vue";
import NodeStatus from "@/components/NodeStatus.vue";
import { app } from "@/backend_sdk";
import { mapState, mapGetters } from "vuex";
import { get_screen_width } from "@/mobile_detector";
import { debounce, isEqual } from "lodash";

import {
  pulse_watcher,
  node_event_watcher,
  entity_watcher,
} from "@/backend_sdk";

import { determine_event_type, construct_event_message } from "@/utils";

import { notify_user, supports_notifications } from "@/notification_utils";

export default {
  name: "App",
  components: {
    TheNavBar,
    NodeStatus,
  },
  data() {
    return {
      which_node_to_watch: "side_driveway",
    };
  },
  created() {
    app.auth().onAuthStateChanged((user) => {
      this.$store.commit("set_user", user);
      if (this.$store.state.user && this.$route.name == "Login") {
        this.$router.push("/");
      }
    });
    window.addEventListener("resize", this.update_screen_size());
  },
  mounted() {
    this._screen_size_workhorse();
    pulse_watcher(this.location, this.pulse_event_handler);
    this.$store.commit("set_supports_notifications", supports_notifications());
    node_event_watcher(
      this.node_event_handler,
      this.location,
      this.display_date,
      this.which_node_to_watch
    );
    entity_watcher(this.location, this.entity_event_handler);
  },
  props: {},
  computed: {
    ...mapState([
      "user",
      "latest_health_check",
      "display_date",
      "supports_notifications",
      "last_notification_event_types",
    ]),
    ...mapGetters([
      "location",
      "user_was_notified_recently",
      "get_latest_event_type",
      "has_narrow_screen",
    ]),
  },
  unmounted() {
    window.removeEventListener("resize", this.update_screen_size());
  },
  methods: {
    entity_event_handler: function (data) {
      this.$store.commit("set_entities", data);
    },
    pulse_event_handler: function (data) {
      console.log("Got New Health Check Data:", data);
      this.$store.commit("set_latest_pulse_data", data);
    },
    _screen_size_workhorse: function () {
      let width = get_screen_width();
      this.$store.commit("update_screen_width", width);
    },
    update_screen_size: function () {
      return debounce(this._screen_size_workhorse, 150);
    },
    node_event_handler: function (data) {
      console.log("Got New Event Data:", data);
      this.$store.commit("append_to_days_events", data);
      this.notify_user_if_event_is_right_sort(data);
    },
    notify_user_if_event_is_right_sort: function (data) {
      let can_notify = this.supports_notifications;
      let events_are_new_types =
        this.latest_notification_event_is_different_to_current_event(data);
      let should_notify =
        !this.user_was_notified_recently || events_are_new_types;

      if (should_notify && can_notify) {
        notify_user(construct_event_message(data));
        this.$store.commit(
          "set_last_notification_time",
          new Date().getTime() / 1000
        );
        this.$store.commit("set_latest_notification_event_type", [
          ...new Set(data.map((x) => determine_event_type(x))),
        ]);
      }
    },
    latest_notification_event_is_different_to_current_event: function (
      curr_events
    ) {
      let curr_event_types = [
        ...new Set(curr_events.map((x) => determine_event_type(x))),
      ];
      return !isEqual(
        this.last_notification_event_types.sort(),
        curr_event_types.sort()
      );
    },
    node_status_clicked: function () {},
  },
};
</script>

<style>
/*
 * ****************** GLOBAL STYLES ******************
 */
* {
  margin: 0;
}

.small-icon {
  height: 30px;
}

/*
 * ****************** APP COMPONENT STYLES ******************
 */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
