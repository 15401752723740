<template>
  <div class="event-display">
    <ListDisplayMetaInfo
      :index="event_index"
      :total="total_events"
      :event_visible="event_visible"
      @toggle_visibility="toggle_visibility()"
    />
    <EventDisplayTitle
      :timestamp="title_timestamp"
      :event_type="title_event_type"
      :invoking_rule="title_invoking_rule"
      :camera="title_camera"
      :num_tracked_entities="title_num_entities"
    />
    <EventDisplayImage
      :img_src="img_src"
      :message="img_message"
      v-if="event_visible && event_has_an_image()"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash";

import ListDisplayMetaInfo from "@/components/ListDisplayMetaInfo.vue";
import EventDisplayTitle from "@/components/EventDisplayTitle.vue";
import EventDisplayImage from "@/components/EventDisplayImage.vue";

import {
  event_is_valid,
  get_date_from_event,
  get_time_from_event,
  determine_event_type,
} from "@/utils";
import { get_cortex_image_url } from "@/backend_sdk";

import {
  ERROR_INDICATOR,
  LOADING_INDICATOR,
  SUCCESS_INDICATOR,
  RULE_NAME_FOR_SYSTEM_STATUS_MESSAGES,
  NOT_APPLICABLE_STRING,
  RETRY_IMAGE_WAIT_TIME_SECONDS,
  RETRYING_IMAGE_LOAD_MSG,
  MAX_IMAGE_LOADING_RETRIES,
} from "@/utils/cortex_constants";

export default {
  name: "EventDisplay",
  components: {
    ListDisplayMetaInfo,
    EventDisplayTitle,
    EventDisplayImage,
  },
  props: {
    event_index: {
      type: Number,
      required: true,
    },
    total_events: {
      type: Number,
      required: true,
    },
    payload: {
      type: Object,
      required: true,
      validator: (value) => {
        return event_is_valid(value);
      },
    },
  },
  data() {
    return {
      img_src: "",
      img_message: "",
      retry_image_load_in: -1,
      num_errors_while_loading: 0,
      event_visible: true,
    };
  },
  computed: {
    ...mapGetters(["location"]),
    event_date: function () {
      return get_date_from_event(this.payload);
    },
    event_time: function () {
      return get_time_from_event(this.payload);
    },
    threat_mind_name: function () {
      return this.payload.invoking_threat_mind;
    },
    title_timestamp: function () {
      return (
        this.payload.crashed_at ||
        this.payload.deactivated_at ||
        this.payload.activated_at ||
        this.payload.initialised_at ||
        this.payload.sr_instantiation_timestamp
      );
    },
    title_event_type: function () {
      return determine_event_type(this.payload, true).replaceAll("_", " ");
    },
    title_invoking_rule: function () {
      return this.payload.invoking_rule || RULE_NAME_FOR_SYSTEM_STATUS_MESSAGES;
    },
    title_camera: function () {
      return this.payload.camera || NOT_APPLICABLE_STRING;
    },
    title_num_entities: function () {
      return this.payload.num_tracked_entities || NOT_APPLICABLE_STRING;
    },
  },
  mounted() {
    if (this.event_has_an_image()) {
      this.img_message = LOADING_INDICATOR;
      this.get_event_image_url();
    } else {
      this.img_message = "";
    }
  },
  methods: {
    event_has_an_image: function () {
      return "invoking_threat_mind" in this.payload;
    },
    get_event_image_url: function () {
      get_cortex_image_url(
        this.location,
        this.event_date,
        this.event_time,
        this.threat_mind_name
      )
        .then((url) => {
          // `url` is the download URL for the arixian image
          // Or inserted into an <img> element
          // const img = document.getElementById('myimg');
          // img.setAttribute('src', url);
          this.img_src = url;
          this.img_message = SUCCESS_INDICATOR;
        })
        .catch(() => {
          // Handle any errors
          this.num_errors_while_loading++;
          this.initialise_image_load_retry_timeout();
          // this.img_message = ERROR_INDICATOR;
        });
    },
    toggle_visibility: function () {
      this.event_visible = !this.event_visible;
    },
    initialise_image_load_retry_timeout: function () {
      if (this.num_errors_while_loading > MAX_IMAGE_LOADING_RETRIES) {
        this.img_message = ERROR_INDICATOR;
      } else {
        this.retry_image_load_in = cloneDeep(RETRY_IMAGE_WAIT_TIME_SECONDS);
        this.set_new_image_load_timeout();
      }
    },
    set_new_image_load_timeout: function () {
      this.img_message = cloneDeep(RETRYING_IMAGE_LOAD_MSG).replace(
        "{}",
        this.retry_image_load_in
      );
      setTimeout(() => {
        this.reload_image_if_wait_time_elapsed();
      }, 1000);
    },
    reload_image_if_wait_time_elapsed: function () {
      if (this.retry_image_load_in == 0) {
        this.get_event_image_url();
      } else {
        this.retry_image_load_in--;
        this.set_new_image_load_timeout();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.event-display {
  width: 90%;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
