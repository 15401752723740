<template>
  <div class="node-status-content-wide">
    <div class="node-status__left-side">
      <h3 class="node-status__left-side__title">{{ name }}</h3>
      <span class="node-status__left-side__code-version">{{
        code_version
      }}</span>
    </div>
    <div class="node-status__right-side">
      <span class="node-status--bold-string">{{ status_string }}</span>
      <span
        class="
          node-status__right-side__time_since_contact node-status--small-string
        "
        >{{ time_since_contact_string }}</span
      >
      <span
        class="
          node-status__right-side__last-event-type node-status--bold-string
        "
        >{{ latest_event_type }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "NodeStatusContentWide",
  props: {
    name: {
      type: String,
      required: true,
    },
    status_string: {
      type: String,
      required: true,
    },
    code_version: {
      type: String,
      required: true,
    },
    time_since_contact_string: {
      type: String,
      required: true,
    },
    latest_event_type: {
      type: String,
      required: true,
    },
  },
  computed: {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.node-status-content-wide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  border-top: 2px solid #3a3a3a;
  border-bottom: 2px solid #3a3a3a;
}

.node-status__left-side {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  margin-top: 5px;
  margin-bottom: 5px;
}

.node-status__left-side__title {
  padding-left: 10px;
  font-size: 14px;
}

.node-status__left-side__code-version {
  margin: 0px 10px 0px 10px;
  padding: 2px 5px 2px 5px;
  border-radius: 15px;
  font-size: 12px;
  font-weight: bold;
  background-color: mediumspringgreen;
}

.node-status__right-side {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  margin-top: 5px;
  margin-bottom: 5px;
}

.node-status__right-side__time_since_contact {
  margin-left: 10px;
}

.node-status--small-string {
  font-size: 12px;
  font-style: italic;
  font-weight: lighter;
}

.node-status__right-side__last-event-type {
  margin-right: 10px;
  margin-left: 10px;
}

.node-status--bold-string {
  font-weight: bold;
  font-size: 12px;
}
</style>
