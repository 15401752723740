// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyCB-YK1b6_tDQVgMRy-TWT3QMpnc5ima98",
  authDomain: "eloquent-hangar-325815.firebaseapp.com",
  projectId: "eloquent-hangar-325815",
  storageBucket: "eloquent-hangar-325815.appspot.com",
  messagingSenderId: "745633452933",
  appId: "1:745633452933:web:c6b12edddbcda36b87aaec",
  measurementId: "G-0PR69BY307",
};

// Initialize Firebase
// export const app = initializeApp(firebaseConfig);
// export const analytics = getAnalytics(app);
// export const auth = getAuth(app);

import firebase from "firebase/compat/app";
import { getFirestore } from "firebase/firestore";

export const app = firebase.initializeApp(firebaseConfig);

export const do_firebase_signout = function () {
  firebase.auth().signOut();
};

import {
  collection,
  getDocs,
  query,
  onSnapshot,
  // getDoc,
  doc,
  // where
} from "firebase/firestore";

const db = getFirestore();

// const listener = onSnapshot(doc(db, "locations/brickfield-farm--stormy-nation-tale"), (doc) => {
//   return doc.data();
// });

export const pulse_watcher = function (location_id, callback) {
  return onSnapshot(doc(db, `locations/${location_id}`), (doc) => {
    callback(doc.data());
  });
};

export const entity_watcher = function (location_id, callback) {
  const entities = query(collection(db, `locations/${location_id}/entities`));
  onSnapshot(entities, (query_snapshot) => {
    let result = {};

    query_snapshot.docChanges().forEach((change) => {
      result[change.doc.ref.id] = change.doc.data();
    });
    callback(result);
  });
};

export const node_event_watcher = function (
  callback,
  location_id,
  date,
  node_name
) {
  const node_events = query(
    collection(
      db,
      `locations/${location_id}/cortex-messages/${date}/${node_name}`
    )
  );
  onSnapshot(node_events, (query_snapshot) => {
    let result = [];

    query_snapshot.docChanges().forEach((change) => {
      result.push(change.doc.data());
    });
    callback(result);
  });
};

export const backend_get_available_dates = async function (location_id) {
  const cortexmsgs = query(
    collection(db, `locations/${location_id}/cortex-messages`)
  );
  const querySnapshot = await getDocs(cortexmsgs);
  let result = [];
  querySnapshot.forEach((doc) => {
    result.push(doc.id);
  });
  return result;
};

export const backend_get_events = async function (location_id, date) {
  const cortexmsgs = query(
    collection(
      db,
      `locations/${location_id}/cortex-messages/${date}/front_gate`
    )
  );

  const querySnapshot = await getDocs(cortexmsgs);
  let result = [];

  querySnapshot.forEach((doc) => {
    result.push(doc.data());
  });
  return result;

  // var cortexmsgs = getDocs(collectionGroup(db, 'front_gate').where("camera", "=", "front_gate"));
  // console.log(cortexmsgs)

  // cortexmsgs.then((querySnapshot) => {
  //     querySnapshot.forEach((doc) => {
  //         console.log(doc.id, ' => ', doc.data());
  //     });
  // });

  // const querySnapshot = await getDocs(collection(db, "front-gate"));
  // console.log(querySnapshot)
  // querySnapshot.forEach((doc) => {
  //   console.log(doc.data())
  //   console.log(`${doc.id} => ${doc.data()}`);
  // });
};

import { getStorage, ref, getDownloadURL } from "firebase/storage";

const storage = getStorage(app);

export const get_cortex_image_url = function (
  location_id,
  date_string,
  time_string,
  invoking_threat_mind_name
) {
  // let storageUrl = `${location_id}/${date_string}/${time_string}/${invoking_threat_mind_name}_rescaled_latest_img.jpeg`;
  let storageUrl = `${location_id}/${date_string}/${time_string}/${invoking_threat_mind_name}_gcs_analysed_wave.jpeg`;
  let storageRef = ref(storage, storageUrl);
  return getDownloadURL(storageRef);
};

/**
 * Function that gets the outline JSON to display a location radar.
 *
 * @param {String} location_id a string identifying the location to get the outline for.
 */
export const get_location_outline = function (location_id) {
  console.log(`Getting outline for ${location_id}...`);
  let outline = require("./outline_example.json");
  console.log("Outline:", outline);
  return outline;
};

/*
>>>> LISTENERS <<<<
- location_metadata_listener(location_id, callback)
- location_entity_listener(location_id, callback)
- single_node_event_listener(location_id, node_name, callback)
    !! event passed to callback must include the event date and time in the hyphenated format

>>>> SETTERS <<<<
- set_system_mode(location_id, new_mode)
- set_is_watched(location_id, node_name)
- (future) set_subscription_preference(location_id, event_type, yes_or_no)

>>>> GETTERS <<<<
- get_specific_date_events(location_id, date)
- get_available_nodes(location_id)
- get_available_dates(location_id, node_name)
- get_event_types(location_id)
- get_system_modes(location_id)
- get_location_outline(location_id)
- get_image_url(location_id, event)

    event :: {
      "node_name": NODE_NAME,
      "event_type": EVENT_TYPE,
      "event_date": DATE_OF_EVENT
      "event_time": SENT_TIMESTAMP,
      "cortex_message": MESSAGE
    }

Flow:
- Page mounts
- Loading message appears
- Gets the available nodes
- Gets the event types
- Gets the system modes
- Gets the location outline
- Sets the location metadata listener
- Sets the entity listener
- Sets event listeners for every node
- Done message appears
- Main screen loads showing the location outline and the node health bar
- Challenge, speak it out loud (temporary)
- Threat, turn everything red, play alarm
*/
