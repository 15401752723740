<template>
  <div class="node-info-piece">
    <h3>{{ title }}</h3>
  </div>
</template>

<script>
export default {
  name: "NodeInfoPiece",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  computed: {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.node-info-piece {
  background-color: mediumspringgreen;
}
</style>
