<template>
  <div class="the-event-display">
    <EventGroupDisplay
      v-for="(egroup, index) in group_days_events"
      :group_index="index"
      :total_groups="group_days_events.length"
      :eventgroup="egroup"
      :key="`cortex-event-group-${index}`"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventGroupDisplay from "@/components/EventGroupDisplay.vue";

export default {
  name: "TheEventDisplay",
  components: {
    EventGroupDisplay,
  },
  props: {},
  computed: {
    ...mapGetters(["group_days_events"]),
  },
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.the-event-display {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
