import { cloneDeep } from "lodash";

export const mutations = {
  set_user(state, user) {
    state.user = user;
  },
  set_available_dates(state, new_dates) {
    state.available_dates = new_dates;
  },
  set_display_date(state, new_date) {
    if (!state.available_dates.includes(new_date)) {
      return;
    }
    state.display_date = new_date;
  },
  update_screen_width(state, new_width) {
    state.screen_width = new_width;
  },
  set_days_events(state, new_days_events) {
    state.days_events = new_days_events;
  },
  append_to_days_events(state, new_events) {
    state.days_events = new_events.concat(state.days_events);
  },
  set_latest_pulse_data(state, new_pulse_data) {
    state.latest_health_check = cloneDeep(new_pulse_data.nodes);
    state.set_system_mode_from_firestore = cloneDeep(
      new_pulse_data.set_system_mode
    );
    state.running_system_mode = cloneDeep(new_pulse_data.running_system_mode);
    state.used_hard_drive_percentage = cloneDeep(
      new_pulse_data.used_hard_drive_percentage
    );
    console.log("After pulse update:", state);
  },
  set_supports_notifications(state, does_support) {
    state.supports_notifications = does_support;
  },

  /**
   * Sets the time of the last notification to the user in seconds.
   * @param {Object} state Vuex state
   * @param {Number} last_notification_time time in integer seconds
   */
  set_last_notification_time(state, last_notification_time) {
    state.last_notification_time = last_notification_time;
  },

  /**
   * Sets what type of event last triggered a user notification. Types can be seen in `src > utils > cortex_constants.js > SYSTEM_MESSAGE_TYPES`.
   * @param {Object} state Vuex state
   * @param {String} last_notification_event_type type identifier for the last event that triggered a user notification.
   */
  set_latest_notification_event_type(state, last_notification_event_types) {
    state.last_notification_event_types = last_notification_event_types;
  },

  /**
   * Sets entities - the things the system is tracking.
   *
   * @param {Object} state Vuex state
   * @param {Array} new_entities list of dicts, dicts have keys of entity names and values entity info.
   */
  set_entities(state, new_entities) {
    state.entities = new_entities;
  },
};
