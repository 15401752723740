<template>
  <div class="mode-selector">
    <h3>I am a mode selector</h3>
    <!-- <div class="mode-selector__running-mode">
      {{ running_system_mode }}
    </div>
    <select
      name="mode-selector__dropdown"
      :value="system_mode_user_set"
      @input="mode_selected($event)"
      class="mode-selector__dropdown"
    >
      <option
        class="mode-selector__dropdown__option"
        v-for="(mode, index) in available_modes"
        :value="mode"
        :key="`mode-${mode}-${index}`"
      >
        {{ mode }}
      </option>
    </select> -->
  </div>
</template>

<script>
// import { mapState } from "vuex";

import { cloneDeep } from "lodash";
import { SYSTEM_MODES } from "@/utils/cortex_constants";

export default {
  name: "ModeSelector",
  components: {},
  props: {},
  data() {
    return {
      selected_mode: "",
    };
  },
  computed: {
    // ...mapState([
    //   "running_system_mode",
    //   "system_mode_user_set",
    //   "set_system_mode_from_firestore",
    // ]),
    available_modes: function () {
      return cloneDeep(SYSTEM_MODES);
    },
  },
  mounted() {},
  methods: {
    mode_selected: function (event) {
      console.log("A mode was selected:", event.target.value);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
