import {
  do_firebase_signout,
  backend_get_available_dates,
  backend_get_events,
} from "@/backend_sdk";

export const actions = {
  signout() {
    do_firebase_signout();
  },
  get_available_dates({ state, commit }) {
    backend_get_available_dates(state.location_name + "--" + state.location_id)
      .then((dates) => {
        commit("set_available_dates", dates);
      })
      .catch((error) => {
        console.log("Error getting available dates:", error);
      });
  },
  get_events_specified_by_state({ state, commit }) {
    backend_get_events(
      state.location_name + "--" + state.location_id,
      state.display_date
    )
      .then((events) => {
        commit("set_days_events", events);
      })
      .catch((error) => {
        console.log("Error getting the day's events:", error);
      });
  },
};
