<template>
  <div class="event-group-display">
    <ListDisplayMetaInfo
      :index="group_index"
      :total="total_groups"
      :event_visible="show_events"
      @toggle_visibility="toggle_event_visibility()"
    />
    <EventGroupTitle />
    {{ time_indicator_string }}
    <h1 @click="make_gif()">Event Group Display</h1>

    <div class="event-group-display__imgs">
      <img
        v-for="(url, idx) in urls_after_show_idx"
        :key="`img-${idx}-url`"
        class="thumbnail"
        :src="url"
        :id="url"
      />
      <img :src="url_at_show" class="main" />
      <img
        v-for="(url, idx) in urls_up_to_show_idx"
        :key="`img-${idx}-url`"
        class="thumbnail"
        :src="url"
        :id="url"
      />
    </div>

    <div class="button-collection">
      <div
        class="event-group-display__button event-group-display__control-button"
        @click="increment_shown_idx()"
      >
        &#8249;
      </div>
      <div
        class="event-group-display__button event-group-display__control-button"
        @click="toggle_play()"
      >
        <div v-if="playing" class="pause">
          <div class="left-pause"></div>
          <div class="right-pause"></div>
        </div>
        <div v-else>&#9658;</div>
      </div>
      <div
        class="event-group-display__button event-group-display__control-button"
        @click="decrement_shown_idx()"
      >
        &#8250;
      </div>
    </div>

    <div class="event-group-display__events-container" v-if="show_events">
      <!-- <EventDisplay
        v-for="(msg, index) in eventgroup"
        :event_index="index + 1"
        :total_events="eventgroup.length"
        :payload="msg"
        :key="`msg-${index}-${msg.sr_instantiation_timestamp}`"
      /> -->

      <EventDisplay
        :event_index="shown_idx + 1"
        :total_events="eventgroup.length"
        :payload="display_event"
        :key="`msg-${shown_idx}-${display_event.sr_instantiation_timestamp}`"
      />
    </div>

    <div class="event-group-display__stopper">
      &#x25C6; &#x25C6; &#x25C6; &#x25C6; &#x25C6;
    </div>
  </div>
</template>

<script>
import ListDisplayMetaInfo from "@/components/ListDisplayMetaInfo.vue";
import EventGroupTitle from "@/components/EventGroupTitle.vue";
import EventDisplay from "@/components/EventDisplay.vue";
import { mapGetters } from "vuex";

import {
  event_is_valid,
  get_date_from_event,
  get_time_from_event,
} from "@/utils";

import { get_cortex_image_url } from "@/backend_sdk";

export default {
  name: "EventGroupDisplay",
  components: {
    ListDisplayMetaInfo,
    EventGroupTitle,
    EventDisplay,
  },
  props: {
    group_index: {
      type: Number,
      required: true,
    },
    total_groups: {
      type: Number,
      required: true,
    },
    eventgroup: {
      type: Array,
      required: true,
      validator: (value) => {
        return value.every(event_is_valid);
      },
    },
  },
  data() {
    return {
      show_events: true,

      // Start from the end as the events are sorted so that the last item in the array is
      // the most recent time-wise.
      shown_idx: this.eventgroup.length - 1,

      playing: false,

      urls: [],
    };
  },
  computed: {
    ...mapGetters(["location"]),
    group_start_time: function () {
      let first_event = this.eventgroup[this.eventgroup.length - 1];
      return this.get_readable_time_from_iso(first_event.message_timestamp);
    },
    group_end_time: function () {
      let last_event = this.eventgroup[0];
      return this.get_readable_time_from_iso(last_event.message_timestamp);
    },
    num_events: function () {
      if (this.eventgroup.length == 1) {
        return "1 event";
      } else {
        return `${this.eventgroup.length} events`;
      }
    },
    time_indicator_string: function () {
      if (this.eventgroup.length == 1) {
        return `${this.num_events} at ${this.group_start_time}`;
      } else {
        return `${this.num_events} from ${this.group_start_time} to ${this.group_end_time}`;
      }
    },
    display_event: function () {
      return this.eventgroup[this.shown_idx];
    },

    urls_up_to_show_idx: function () {
      return this.urls.slice(0, this.shown_idx);
    },

    urls_after_show_idx: function () {
      return this.urls.slice(this.shown_idx + 1, this.eventgroup.length);
    },

    url_at_show: function () {
      return this.urls[this.shown_idx];
    },
  },
  mounted() {
    this.get_img_urls();
  },
  methods: {
    get_readable_time_from_iso: function (iso_time) {
      let time = new Date(iso_time);
      return `${time.getHours()}:${time
        .getMinutes()
        .toString()
        .padStart(2, 0)}:${time.getSeconds()}`;
    },
    toggle_event_visibility: function () {
      this.show_events = !this.show_events;
    },
    increment_shown_idx: function () {
      this.shown_idx++;
      this.shown_idx =
        this.shown_idx > this.eventgroup.length - 1
          ? this.eventgroup.length - 1
          : this.shown_idx;
    },
    decrement_shown_idx: function () {
      this.shown_idx--;
      this.shown_idx = this.shown_idx < 0 ? 0 : this.shown_idx;
    },
    toggle_play: function () {
      if (this.playing) {
        this.stop_play();
      } else {
        this.start_play();
      }
    },
    start_play: function () {
      this.shown_idx = this.eventgroup.length - 1;
      this.playing = true;
      window.setTimeout(this.increment_while_not_finished, 2000);
    },
    stop_play: function () {
      this.playing = false;
    },
    increment_while_not_finished: function () {
      if (!this.playing) {
        return;
      }

      if (this.shown_idx == 0) {
        this.playing = false;
      } else {
        this.shown_idx--;
        window.setTimeout(this.increment_while_not_finished, 2000);
      }
    },

    get_img_urls: async function () {
      for (let event of this.eventgroup) {
        let url = await get_cortex_image_url(
          this.location,
          get_date_from_event(event),
          get_time_from_event(event),
          event.invoking_threat_mind
        );
        this.urls.push(url);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.event-group-display {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 99%;
  margin-bottom: 30px;

  border-left: 2px solid mediumspringgreen;
  border-right: 2px solid mediumspringgreen;
  /* box-shadow: 0px 0px 7px mediumspringgreen; */
}

.event-group-display__imgs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.button-collection {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.event-group-display__events-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
  overflow-x: scroll;
}

.event-group-display__stopper {
  margin-top: 30px;
  margin-bottom: 30px;
}

.event-group-display__control-button {
  background-color: mediumspringgreen;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  font-size: 32px;
  user-select: none;
}

.event-group-display__button {
  width: 100px;
  height: 40px;
  border-radius: 10px;
  margin-right: 10px;
}

.pause {
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-pause {
  background-color: black;
  height: 25px;
  width: 5px;
  margin-right: 5px;
}

.right-pause {
  background-color: black;
  height: 25px;
  width: 5px;
}

.thumbnail {
  width: 20px;
}

.main {
  width: 300px;
}
</style>
