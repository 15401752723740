<template>
  <div class="node-status-content-narrow">
    <div class="node-status-content-narrow__top">
      <div class="node-status-content-narrow__top__left">
        <span class="node-status-content-narrow--title-text">{{ name }}</span>
        <span
          class="
            node-status-content-narrow--small-text
            node-status-content-narrow__top__left__code_version
          "
        >
          {{ code_version }}
        </span>
      </div>
      <div
        class="
          node-status-content-narrow__top__right
          node-status-content-narrow--title-text
        "
      >
        {{ latest_event_type }}
      </div>
    </div>

    <div class="node-status-content-narrow__bottom">
      <div
        class="
          node-status-content-narrow__bottom__inner
          node-status-content-narrow--small-text
        "
      >
        {{ status_string }} -
        {{ time_since_contact_string }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NodeStatusContentNarrow",
  props: {
    name: {
      type: String,
      required: true,
    },
    status_string: {
      type: String,
      required: true,
    },
    code_version: {
      type: String,
      required: true,
    },
    time_since_contact_string: {
      type: String,
      required: true,
    },
    latest_event_type: {
      type: String,
      required: true,
    },
  },
  computed: {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.node-status-content-narrow {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.node-status-content-narrow--small-text {
  font-size: 10px;
  /* font-weight: bold; */
  font-style: italic;
}

.node-status-content-narrow--title-text {
  font-size: 14px;
  font-weight: bold;
}

.node-status-content-narrow__top {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.node-status-content-narrow__top__left {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10px;
}

.node-status-content-narrow__top__left__code_version {
  margin-left: 5px;
  border-radius: 15px;
  font-weight: bold;
  padding: 1px 4px 1px 4px;
  background-color: mediumspringgreen;
}

.node-status-content-narrow__top__right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
}

.node-status-content-narrow__bottom {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.node-status-content-narrow__bottom__inner {
  margin-left: 10px;
}
</style>
