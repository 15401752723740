<template>
  <div class="login">
    <!-- <h1>Login</h1> -->
    <div id="firebaseui-auth-container"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import { app } from "@/backend_sdk";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      ui: null,
    };
  },
  mounted() {
    let ui = firebaseui.auth.AuthUI.getInstance();
    if (!ui) {
      ui = new firebaseui.auth.AuthUI(app.auth());
    }
    var uiConfig = {
      signInSuccessUrl: "/login",
      signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
    };
    ui.start("#firebaseui-auth-container", uiConfig);
    this.ui = ui;
  },
  unmounted() {
    if (this.ui) {
      this.ui.reset();
    }
  },
};
</script>

<style scoped>
.login {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/background-03.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

h1 {
  color: white;
}

#firebaseui-auth-container {
  width: 100%;
}
</style>
