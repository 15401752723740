<template>
  <div class="event-display-title-icon">
    <img
      :src="require(`@/assets/${icon_name}.png`)"
      :alt="alt_text"
      class="small-icon event-display-title-icon__icon"
    />
  </div>
</template>

<script>
export default {
  name: "EventDisplayTitleIcon",
  components: {},
  props: {
    event_type: {
      type: String,
      required: true,
    },
  },
  computed: {
    icon_name: function () {
      return "detection_event";
    },
    alt_text: function () {
      return this.event_type;
    },
  },
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.event-display-title-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
