<template>
  <div class="the-date-time-display the-date-time-display--dark-mode">
    <input
      type="text"
      autocomplete="off"
      list="dates"
      :value="display_date"
      @input="set_selected"
      class="
        the-date-time-display__input-field
        the-date-time-display__input-field--dark-mode
      "
    />
    <datalist id="dates">
      <option
        v-for="(option, index) in get_sorted_dates"
        :value="option"
        :key="`date-${index}`"
      >
        {{ option }}
      </option>
    </datalist>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "TheDateTimeDisplay",
  props: {},
  computed: {
    ...mapState(["display_date"]),
    ...mapGetters(["get_sorted_dates"]),
  },
  mounted() {
    this.$store.dispatch("get_available_dates");
  },
  methods: {
    set_selected: function (event) {
      this.$store.commit("set_display_date", event.target.value);
      this.$store.dispatch("get_events_specified_by_state");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.the-date-time-display {
  grid-area: dt-display;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
}

.the-date-time-display--dark-mode {
  /* border-bottom: 1px solid mediumspringgreen; */
  color: mediumspringgreen;
  /* background-color: #9b9b9b; */
}

.the-date-time-display__input-field {
  padding: 5px 10px 5px 10px;
  background-color: inherit;
  border: none;
  transition: 0.2s;
  font-size: 20px;
  text-align: center;
  font-family: "Syne Mono", monospace;
}

.the-date-time-display__input-field:focus {
  padding: 5px 30px 5px 30px;
  outline: none;
  transition: 0.2s;
}

.the-date-time-display__input-field--dark-mode {
  border-bottom: 0px solid mediumspringgreen;
  color: mediumspringgreen;
}
</style>
