<template>
  <div class="list-display-meta-info">
    <div class="list-display-meta-info__position-indicator">
      <p>{{ index }} / {{ total }}</p>
    </div>
    <div
      class="list-display-meta-info__close-button"
      @click="$emit('toggle_visibility')"
    >
      <img
        :src="require(`@/assets/${icon_src}.png`)"
        :alt="icon_alt"
        class="small-icon list-display-meta-info__close-button__icon"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "ListDisplayMetaInfo",
  components: {},
  props: {
    index: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    event_visible: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    icon_alt: function () {
      return this.event_visible ? "Hide Event" : "Show Event";
    },
    icon_src: function () {
      return this.event_visible ? "open_eye" : "closed_eye";
    },
  },
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.list-display-meta-info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin-bottom: 3px;
  margin-top: 5px;
}
.list-display-meta-info__position-indicator {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: rgb(161, 161, 161);
}
.list-display-meta-info__close-button {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
