/**
 *
 * @param {Array<String>} cortex_event_types list of strings saying what type of events the user should be notified about
 */
export const notify_user = function (notification_string) {
  // Let's check if the browser supports notifications
  if (!("Notification" in window)) {
    alert("This browser does not support desktop notification");
  }

  // Let's check whether notification permissions have already been granted
  else if (Notification.permission === "granted") {
    // If it's okay let's create a notification
    new Notification(notification_string);
  }

  // Otherwise, we need to ask the user for permission
  else if (Notification.permission !== "denied") {
    Notification.requestPermission().then(function (permission) {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        new Notification(notification_string);
      }
    });
  }

  // At last, if the user has denied notifications, and you
  // want to be respectful there is no need to bother them any more.
};

/**
 * Checks whether the browser supports notifications and returns a boolean value.
 */
export const supports_notifications = function () {
  if (!("Notification" in window)) {
    return false;
  }
  return true;
};
