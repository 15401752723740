import { isEmpty } from "lodash";

import { determine_event_type, is_ping_recent } from "@/utils";

import { SYSTEM_MESSAGE_TYPES } from "@/utils/cortex_constants.js";

/**
 * Narrow screens, for example on mobile phones, will need to display differently.
 * We define here the width in pixels a screen needs to be before it is considered
 * "narrow".
 */
export const NARROW_SCREEN_NUM_PIX = 400;

/**
 * Events occur in groups. For example, if someone walks in front of a camera, then there
 * will be a large collection of events all around the same time that they walked in front
 * of the camera. Later on in the day, maybe a car arrives and generates more events.
 * Each of these separate things observed by the system are different groups of events.
 */
export const TIME_BETWEEN_EVENT_GROUPS_IN_MIN = 5;

/**
 * Location strings are the location name joined with the location ID by a separator
 * defined here.
 */
export const LOCATION_SEPARATOR = "--";

/**
 * Default message that is returned if no health check data has yet been placed in the state.
 */
export const NO_HEALTH_DATA = "NO-HEALTH-DATA";

/**
 * How many minutes are considered "recent" in terms of user notifications. If a user was
 * notified less than this number of minutes ago, then we consider them recently notified.
 */
export const RECENT_MINUTES_USER_NOTIFICATION = 3;

export const is_int_time_recent = function (int_time) {
  if (int_time < 0) {
    return false;
  }
  let curr_time_s = new Date().getTime() / 1000;
  let s_since_notification = curr_time_s - int_time;
  let min_since_notification = s_since_notification / 60;
  return min_since_notification < RECENT_MINUTES_USER_NOTIFICATION;
};

const convert_event_to_iso8601 = function (event) {
  let timestamp = event.sr_instantiation_timestamp;
  let as_iso = new Date(timestamp).getTime();
  return {
    as_int: as_iso,
    original: event,
  };
};

const convert_dtstr_to_iso8601 = function (dtstr) {
  let components = dtstr.split(".");
  let year = components[2];
  let month = components[1];
  let day = components[0];
  return {
    as_int: new Date(`${year}-${month}-${day}`).getTime(),
    original: dtstr,
  };
};

const sorter = function (a, b) {
  if (b.as_int > a.as_int) {
    return 1;
  }

  if (b.as_int < a.as_int) {
    return -1;
  }

  if (b.as_int == a.as_int) {
    return 0;
  }
};

/**
 * Main function to get the events for a day that are stored in the Vuex store. It is
 * extracted as this "_worker" function so that it can be used in other getters.
 *
 * @param {Object} state - the Vuex state
 * @returns a sorted list of the events for the day
 */
const _worker_get_sorted_events_for_the_day = function (state) {
  if (state.days_events.length == 0) {
    return [];
  }

  return state.days_events
    .map(convert_event_to_iso8601)
    .sort(sorter)
    .map((x) => x.original);
};

export const getters = {
  has_narrow_screen(state) {
    return state.screen_width < NARROW_SCREEN_NUM_PIX;
  },

  location(state) {
    return state.location_name + LOCATION_SEPARATOR + state.location_id;
  },

  get_sorted_dates(state) {
    return state.available_dates
      .map(convert_dtstr_to_iso8601)
      .sort(sorter)
      .map((x) => x.original);
  },

  get_sorted_events_for_the_day(state) {
    return _worker_get_sorted_events_for_the_day(state);
  },

  group_days_events(state) {
    let sorted_events = _worker_get_sorted_events_for_the_day(state);

    if (sorted_events.length == 0) {
      return [];
    }

    let previous_event = convert_event_to_iso8601(sorted_events[0]);
    let i = 0;
    let result = [];
    let current_group = [];
    for (let e of sorted_events) {
      if (i < 1) {
        i++;
        current_group.push(e);
        continue;
      }

      let time = convert_event_to_iso8601(e);

      let ms_between_events = previous_event.as_int - time.as_int;
      let s_between_events = ms_between_events / 1000;
      let min_between_events = s_between_events / 60;

      if (min_between_events > TIME_BETWEEN_EVENT_GROUPS_IN_MIN) {
        result.push(current_group);
        current_group = [e];
      } else {
        current_group.push(e);
      }
      previous_event = time;
    }
    result.push(current_group);
    return result;
  },
  node_health(state) {
    if (isEmpty(state.latest_health_check)) {
      return NO_HEALTH_DATA;
    }
    let result = [];
    for (let [key, val] of Object.entries(state.latest_health_check)) {
      result.push({
        node_name: key,
        recent_ping: is_ping_recent(val.pulse_timestamp),
      });
    }
    return result;
  },

  /**
   * Returns true if the user was notified recently.
   * @param {Object} state Vuex state
   */
  user_was_notified_recently(state) {
    return is_int_time_recent(state.last_notification_time);
  },

  /**
   * Returns the type of event last put into the store.
   * @param {Object} state Vuex state
   */
  get_latest_event_type(state) {
    if (state.days_events.length > 0) {
      let events = _worker_get_sorted_events_for_the_day(state);
      return determine_event_type(events[0]);
    } else {
      return SYSTEM_MESSAGE_TYPES.NONE_YET;
    }
  },

  /**
   * Returns the most recent event from the store.
   * @param {Object} state Vuex state
   */
  get_latest_event(state) {
    let sorted_events = _worker_get_sorted_events_for_the_day(state);
    if (sorted_events.length > 0) {
      return sorted_events[0];
    } else {
      return SYSTEM_MESSAGE_TYPES.NONE_YET;
    }
  },

  /**
   * Returns true if any of the entities is a threat.
   * @param {Object} state Vuex state
   */
  get_threatened_nodes(state) {
    let result = [];
    for (let [key, val] of Object.entries(state.entities)) {
      for (let entity_metadata of Object.values(val)) {
        if (
          "entity_tags" in entity_metadata &&
          entity_metadata.entity_tags.includes("threat")
        ) {
          result.push(key);
        }
      }
    }
    return result;
  },

  /**
   * Returns true if any of the entities is a challenge.
   * @param {Object} state Vuex state
   */
  get_challenge_nodes(state) {
    let result = [];
    for (let [key, val] of Object.entries(state.entities)) {
      for (let entity_metadata of Object.values(val)) {
        if (
          "entity_tags" in entity_metadata &&
          entity_metadata.entity_tags.includes("challenge")
        ) {
          result.push(key);
        }
      }
    }
    return result;
  },
};
