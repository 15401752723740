export const CORTEX_MESSAGE_KEYS = {
  SR_TIMESTAMP: "sr_instantiation_timestamp",
  EVENT_TYPE: "event_type",

  // -- DEPRECATED --
  MESSAGE_TIMESTAMP: "message_timestamp",
  INITIALISED_AT: "initialised_at",
  ACTIVATED_AT: "activated_at",
  DEACTIVATED_AT: "deactivated_at",
  CRASHED_AT: "crashed_at",
};

export const ERROR_INDICATOR = "ERROR";
export const LOADING_INDICATOR = "LOADING";
export const NO_IMAGE_INDICATOR = "NO IMAGE";
export const SUCCESS_INDICATOR = "SUCCESS";
export const RETRYING_IMAGE_LOAD_MSG = "Retrying in {}...";

/**
 * Some messages from Cortex are not about what is going on in the world, but are pieces of information about how the system is operating. Such messages do not have an 'invoking rule' parameter, so we need to return a different message.
 */
export const RULE_NAME_FOR_SYSTEM_STATUS_MESSAGES =
  "System Status Notification";

/**
 * Sometimes props need to be passed to components that are not applicable, for example for that kind of event. For that, we need to pass a default string instead.
 */
export const NOT_APPLICABLE_STRING = "N/A";

/**
 * Cortex can send many types of messages. These are enumerated here.
 */
export const SYSTEM_MESSAGE_TYPES = {
  MONITORING_EVENT: "Detection",
  INITIALISATION_EVENT: "Initialisation",
  ACTIVATION_EVENT: "Activation",
  DEACTIVATION_EVENT: "Deactivation",
  CRASH_EVENT: "System Crashed!",
  NONE_YET: "No Events Yet",
  ALL_QUIET: "Secure",
};

/**
 * The events that should send a notification message to the user.
 */
export const SHOULD_NOTIFY_USER_EVENTS = [
  SYSTEM_MESSAGE_TYPES.MONITORING_EVENT,
  SYSTEM_MESSAGE_TYPES.ACTIVATION_EVENT,
  SYSTEM_MESSAGE_TYPES.DEACTIVATION_EVENT,
  SYSTEM_MESSAGE_TYPES.CRASH_EVENT,
];

/**
 * How long in minutes should pass between pulses from the system to say it's alive.
 */
export const EXPECTED_PULSE_DELAY_MINUTES = 2;

/**
 * How long in minutes nothing should be happening for before the system declares that
 * nothing is going on.
 */
export const ATTENTION_SPAN = 2;

/**
 * The string to display on the UI if a node has not checked in recently.
 */
export const NODE_UNREACHABLE = "Unreachable";

/**
 * The string to display on the UI if a node has checked in recently.
 */
export const NODE_ACTIVE = "Active";

/**
 * Time to wait after loading an image returned an error before trying to load it again.
 */
export const RETRY_IMAGE_WAIT_TIME_SECONDS = 3;

/**
 * Number of time to try loading an image before declaring it an image.
 */
export const MAX_IMAGE_LOADING_RETRIES = 5;

/**
 * The different modes that the system can be put into.
 */
export const SYSTEM_MODES = ["sentry", "sunny_day", "dormant"];
