<template>
  <div class="home home--dark-mode">
    <TheMessageBar />
    <TheActuatorsPanel />
    <TheEventDisplay />

    <h1>
      Next: add icons to the event group header to indicate what happened; add
      code version to status bar; suppress error log on no image; flash messages
      in bar when they change; should only notify if events happened recently
      (not on first load); if not on todays date don't add new events to the
      event list; message should flash up at the top if no notification;
      deployed website is not checking the front gate node when it goes down, it
      keeps displaying whatever was there last (I think?)
    </h1>
  </div>
</template>

<script>
// @ is an alias to /src
import TheMessageBar from "@/components/TheMessageBar.vue";
import TheActuatorsPanel from "@/components/TheActuatorsPanel.vue";
import TheEventDisplay from "@/components/TheEventDisplay.vue";

export default {
  name: "Home",
  components: {
    TheMessageBar,
    TheActuatorsPanel,
    TheEventDisplay,
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.home {
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
}

.home--dark-mode {
  background-color: #3a3a3a;
  color: mediumspringgreen;
}
</style>
