<template>
  <div class="node-live-feed">
    <!-- <h1>I am the live feed for a node</h1>
    <NodeTitle />
    <ModeSelector />
    <NodeInfoPiece title="Sit Rep" />
    <NodeInfoPiece title="Status" />
    <NodeInfoPiece title="Last Event" /> -->
    <EventDisplayImage
      :img_src="img_src"
      :message="img_message"
      v-if="event_has_an_image()"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";

import NodeTitle from "@/components/NodeTitle.vue";
import ModeSelector from "@/components/ModeSelector.vue";
import EventDisplayImage from "@/components/EventDisplayImage.vue";
import NodeInfoPiece from "@/components/NodeInfoPiece.vue";

import {
  ERROR_INDICATOR,
  LOADING_INDICATOR,
  SUCCESS_INDICATOR,
  RULE_NAME_FOR_SYSTEM_STATUS_MESSAGES,
  NOT_APPLICABLE_STRING,
  RETRY_IMAGE_WAIT_TIME_SECONDS,
  RETRYING_IMAGE_LOAD_MSG,
  MAX_IMAGE_LOADING_RETRIES,
} from "@/utils/cortex_constants";

import { mapGetters } from "vuex";

import { get_date_from_event, get_time_from_event } from "@/utils";

import { get_cortex_image_url } from "@/backend_sdk";

// let node = {
//   name: "front_gate",
//   is_watched: true,
//   pulse_timestamp: "",
//   code_version: "4.36.0"
// }

// const toggle_is_watched = function(node_name) {

// }

export default {
  name: "NodeLiveFeed",
  components: {
    NodeTitle,
    ModeSelector,
    EventDisplayImage,
    NodeInfoPiece,
  },
  props: {
    node: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      img_src: "",
      img_message: "",
      num_errors_while_loading: 0,
      retry_image_load_in: -1,
    };
  },
  computed: {
    ...mapGetters(["get_latest_event", "location"]),
    event_date: function () {
      if (this.event_has_an_image()) {
        return get_date_from_event(this.get_latest_event);
      } else {
        return "No Events Yet";
      }
    },
    event_time: function () {
      if (this.event_has_an_image()) {
        return get_time_from_event(this.get_latest_event);
      } else {
        return "No Events Yet";
      }
    },
    threat_mind_name: function () {
      if (this.event_has_an_image()) {
        return this.get_latest_event.invoking_threat_mind;
      } else {
        return "No Events Yet";
      }
    },
  },
  watch: {
    get_latest_event: function () {
      this.get_latest_img_src();
    },
  },
  mounted() {
    console.log(this.node);
  },
  methods: {
    event_has_an_image: function () {
      if (this.get_latest_event == "No Events Yet") {
        return false;
      }
      return "invoking_threat_mind" in this.get_latest_event;
    },
    get_latest_img_src: function () {
      if (!this.event_has_an_image) {
        return;
      }
      get_cortex_image_url(
        this.location,
        this.event_date,
        this.event_time,
        this.threat_mind_name
      )
        .then((url) => {
          // `url` is the download URL for the arixian image
          // Or inserted into an <img> element
          // const img = document.getElementById('myimg');
          // img.setAttribute('src', url);
          this.img_src = url;
          this.img_message = SUCCESS_INDICATOR;
          this.num_errors_while_loading = 0;
        })
        .catch(() => {
          // Handle any errors
          this.num_errors_while_loading++;
          this.initialise_image_load_retry_timeout();
          // this.img_message = ERROR_INDICATOR;
        });
    },

    initialise_image_load_retry_timeout: function () {
      if (this.num_errors_while_loading > MAX_IMAGE_LOADING_RETRIES) {
        this.img_message = ERROR_INDICATOR;
      } else {
        this.retry_image_load_in = cloneDeep(RETRY_IMAGE_WAIT_TIME_SECONDS);
        this.set_new_image_load_timeout();
      }
    },

    set_new_image_load_timeout: function () {
      this.img_message = cloneDeep(RETRYING_IMAGE_LOAD_MSG).replace(
        "{}",
        this.retry_image_load_in
      );
      setTimeout(() => {
        this.reload_image_if_wait_time_elapsed();
      }, 1000);
    },

    reload_image_if_wait_time_elapsed: function () {
      if (this.retry_image_load_in == 0) {
        this.get_latest_img_src();
      } else {
        this.retry_image_load_in--;
        this.set_new_image_load_timeout();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.node-live-feed {
  background-color: grey;
  border: 5px solid royalblue;
  padding-bottom: 50px;
}
</style>
