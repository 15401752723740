import {
  CORTEX_MESSAGE_KEYS,
  SYSTEM_MESSAGE_TYPES,
  EXPECTED_PULSE_DELAY_MINUTES,
  ATTENTION_SPAN,
} from "./cortex_constants";

import { isNil } from "lodash";

export const get_todays_date = function () {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  return dd + "." + mm + "." + yyyy;
};

export const event_is_valid = function (cortex_event) {
  let has_timestamp = CORTEX_MESSAGE_KEYS.SR_TIMESTAMP in cortex_event;
  let has_event_type = CORTEX_MESSAGE_KEYS.EVENT_TYPE in cortex_event;
  return has_timestamp && has_event_type;
};

const add_leading_zero_if_single_number = function (n) {
  return n.length > 1 ? n : n.padStart(2, "0");
};

export const get_readable_date_from_timestamp = function (timestamp_str) {
  let timestamp = new Date(timestamp_str);
  let day = add_leading_zero_if_single_number(String(timestamp.getDate()));
  let month = add_leading_zero_if_single_number(
    String(timestamp.getMonth() + 1)
  );
  let year = timestamp.getFullYear();
  return `${day}.${month}.${year}`;
};

export const get_readable_time_from_timestamp = function (timestamp_str) {
  let timestamp = new Date(timestamp_str);
  let hours = add_leading_zero_if_single_number(
    String(timestamp.getUTCHours())
  );
  let minutes = add_leading_zero_if_single_number(
    String(timestamp.getUTCMinutes())
  );
  let seconds = add_leading_zero_if_single_number(
    String(timestamp.getUTCSeconds())
  );
  return `${hours}-${minutes}-${seconds}`;
};

/**
 * Extracts the date of a cortex event in the correct string format ({DAY}.{MONTH}.{YEAR}) to query Cloud Storage.
 * @param {Object} cortex_event a JSON dictionary message as structured by Cortex (see tests/unit/testing-constants.js for examples).
 */
export const get_date_from_event = function (cortex_event) {
  let timestamp = cortex_event.sr_instantiation_timestamp;
  return get_readable_date_from_timestamp(timestamp);
};

/**
 * Extracts the time of a cortex event in the correct string format ({HOUR}-{MINUTE}-{SECOND}) to query Cloud Storage.
 * @param {Object} cortex_event a JSON dictionary message as structured by Cortex (see tests/unit/testing-constants.js for examples).
 */
export const get_time_from_event = function (cortex_event) {
  let timestamp = cortex_event.sr_instantiation_timestamp;
  return get_readable_time_from_timestamp(timestamp);
};

/**
 * Determines what type of event this message from the system is.
 * @param {Object} cortex_event a JSON dictionary message as structured by Cortex (see tests/unit/testing-constants.js for examples).
 */
export const determine_event_type = function (cortex_event, ignore_time_since) {
  if (isNil(ignore_time_since)) {
    ignore_time_since = false;
  }

  if (!(CORTEX_MESSAGE_KEYS.EVENT_TYPE in cortex_event)) {
    throw new Error("No event type!");
  }

  let event_time = cortex_event.sr_instantiation_timestamp;
  let time_since_event = time_between_timestamp_and_now(event_time) / 60;

  if (time_since_event > ATTENTION_SPAN && !ignore_time_since) {
    return SYSTEM_MESSAGE_TYPES.ALL_QUIET;
  } else {
    return cortex_event.event_type;
  }
};

/**
 *
 * @param {Array<Object>} event_array an array of new events to send a message about
 * @returns
 */
export const construct_event_message = function (event_array) {
  let event_types = event_array.map((x) => determine_event_type(x));
  event_types = [...new Set(event_types)];
  return event_types.join(", ");
};

/**
 * Returns the time between the given timestamp and now.
 *
 * @param {String} timestamp an ISO-format string, e.g. "2021-10-01T09:15:30.447951".
 */
export const time_between_timestamp_and_now = function (timestamp) {
  // Remove the addition of 60 * 60 * 1000 when not in British Summer Time (it's a horrible hack I know)
  let ping_time = new Date(timestamp).getTime(); // + 60 * 60 * 1000;
  let current_time = new Date().getTime();
  let difference_ms = current_time - ping_time;
  let difference_s = difference_ms / 1000;
  return Math.round(difference_s);
};

export const is_ping_recent = function (timestamp) {
  // Remove the addition of 60 * 60 * 1000 when not in British Summer Time (it's a horrible hack I know)
  let ping_time = new Date(timestamp).getTime(); // + 60 * 60 * 1000;
  let current_time = new Date().getTime();
  let difference_ms = current_time - ping_time;
  let difference_s = difference_ms / 1000;
  return difference_s < EXPECTED_PULSE_DELAY_MINUTES * 60;
};
