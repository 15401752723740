<template>
  <div class="live">
    <!-- <TheLocationRadar
      :screen_width="screen_width"
      :location_id="location"
      :node_health_checks="latest_health_check"
      :most_recent_event="get_latest_event"
      :entities="entities"
    /> -->
    <NodeLiveFeed
      v-for="(item, key, index) in latest_health_check"
      :key="`node-live-feed-${index}`"
      :node="item"
    />
  </div>
</template>

<script>
// @ is an alias to /src
// import TheLocationRadar from "@/components/TheLocationRadar.vue";
import NodeLiveFeed from "@/components/NodeLiveFeed.vue";

import { mapState, mapGetters } from "vuex";

export default {
  name: "Live",
  components: {
    // TheLocationRadar,
    NodeLiveFeed,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["latest_health_check", "screen_width", "entities"]),
    ...mapGetters(["location", "get_latest_event"]),
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped></style>
