let get_screen_width = function () {
  return (
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
  );
};

exports.get_screen_width = get_screen_width;

const MIN_WIDTH = 725;
exports.MIN_WIDTH = MIN_WIDTH;

let browser_is_mobile = function () {
  let width = get_screen_width();
  return width < MIN_WIDTH;
};

exports.browser_is_mobile = browser_is_mobile;
