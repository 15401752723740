<template>
  <div class="node-status" @click="$emit('click')">
    <NodeStatusContentNarrow
      v-if="narrow"
      :name="name"
      :status_string="status_string"
      :code_version="code_version"
      :time_since_contact_string="time_since_contact_string"
      :latest_event_type="latest_event_type"
    />
    <NodeStatusContentWide
      :name="name"
      :status_string="status_string"
      :code_version="code_version"
      :time_since_contact_string="time_since_contact_string"
      :latest_event_type="latest_event_type"
      v-else
    />
  </div>
</template>

<script>
import { time_between_timestamp_and_now, is_ping_recent } from "@/utils";
import { NODE_ACTIVE, NODE_UNREACHABLE } from "@/utils/cortex_constants";

import NodeStatusContentWide from "@/components/NodeStatusContentWide.vue";
import NodeStatusContentNarrow from "@/components/NodeStatusContentNarrow.vue";

export default {
  name: "NodeStatus",
  components: {
    NodeStatusContentWide,
    NodeStatusContentNarrow,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    latest_data: {
      type: Object,
      required: true,
    },
    latest_event_type: {
      type: String,
      required: true,
    },
    narrow: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      time_since: -1,
    };
  },
  computed: {
    status_string: function () {
      if (is_ping_recent(this.latest_data.pulse_timestamp)) {
        return NODE_ACTIVE;
      } else {
        return NODE_UNREACHABLE;
      }
    },
    time_since_contact_string: function () {
      if (this.time_since < 60) {
        return `${this.time_since}s since contact`;
      } else {
        let mins = Math.round(this.time_since / 60);
        let leftover_secs = this.time_since % 60;
        return `${mins}m ${leftover_secs}s since contact`;
      }
    },
    code_version: function () {
      return `v${this.latest_data.code_version.trim()}`;
    },
  },
  mounted() {
    window.setInterval(this.recompute_time_since, 100);
  },
  methods: {
    recompute_time_since: function () {
      this.time_since = time_between_timestamp_and_now(
        this.latest_data.pulse_timestamp
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.node-status {
}
</style>
