<template>
  <div
    class="system-status-display"
    :class="{
      'system-status-display--error': status != 'OK',
      'system-status-display--ok': status == 'OK',
    }"
  >
    <span> {{ name }} </span>
    <span> {{ status }} </span>
  </div>
</template>

<script>
export default {
  name: "SystemStatusDisplay",
  components: {},
  props: {
    name: {
      type: String,
      required: true,
      validator: (value) => {
        if (value === "") {
          return false;
        } else {
          return true;
        }
      },
    },
    status: {
      type: String,
      required: true,
      validator: (value) => {
        if (value === "") {
          return false;
        } else {
          return true;
        }
      },
    },
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.system-status-display {
  height: 100%;
  padding: 0px 5px 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 10px;
  font-weight: bolder;
  font-family: "Syne Mono", monospace;
}

.system-status-display--ok {
  /* border-bottom: 1px solid mediumspringgreen; */
  color: white;
  background-color: #7ddc1f;
  /* background-color: #9b9b9b; */
}

.system-status-display--error {
  background-color: #ff6666;
  color: white;
}
</style>
