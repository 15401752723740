<template>
  <div class="event-group-title"></div>
</template>

<script>
export default {
  name: "EventGroupTitle",
  components: {},
  props: {},
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
