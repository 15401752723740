<template>
  <div class="the-navbar the-navbar--dark-mode">
    <div class="link-container">
      <router-link to="/" id="live-link" class="the-navbar__navlink--dark-mode"
        >Live</router-link
      >
      |
      <router-link
        to="/home"
        id="home-link"
        class="the-navbar__navlink--dark-mode"
        >Archive</router-link
      >
    </div>

    <div class="user-container">
      <a v-if="user" id="signout-link" @click="signoutButtonPressed($event)"
        >Logout</a
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TheNavBar",
  props: {},
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    signoutButtonPressed(e) {
      e.stopPropagation();
      this.$store.dispatch("signout");
      this.$router.push("Login");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.the-navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px 10px 20px;
}

.the-navbar--dark-mode {
  border-bottom: 4px solid black;
  background-color: mediumspringgreen;
  color: #222;
  font-weight: bold;
}

.the-navbar__navlink--dark-mode {
  color: #222;
}

.the-navbar__navlink--dark-mode:active {
  background-color: black;
  color: mediumspringgreen;
}

a {
  padding: 0px 10px 0px 10px;
}

a:hover {
  cursor: pointer;
  font-weight: bolder;
  transition: 0.2s;
}

a:active {
  cursor: grabbing;
  border-radius: 10px;
  transition: 0.2s;
}

.link-container {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.user-container {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
